import React from 'react'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import './pageStyles/disclaimer.scss'

const Disclaimer = () => {
  return (
    <Layout>
      <Seo title="Disclaimer" />
      <article id="post-176" className="post-176 page type-page status-publish hentry">
        <div className="entry-content">
          <div className="disclaimer-l et-l disclaimer-l--post et-l--post">
            <div className="disclaimer_builder_inner_content et_builder_inner_content disclaimer_pb_gutters3 et_pb_gutters3">
              <div className="disclaimer_pb_section et_pb_section disclaimer_pb_section_7 et_pb_section_7 disclaimer_pb_with_background et_pb_with_background disclaimer_section_regular et_section_regular section_has_divider disclaimer_pb_bottom_divider et_pb_bottom_divider disclaimer_pb_top_divider et_pb_top_divider optml-bg-lazyloaded disclaimer_pb_section_first et_pb_section_first" data-fix-page-container="on" style={{ paddingTop: '168px' }}>
                <div className="disclaimer_pb_row et_pb_row disclaimer_pb_row_8 et_pb_row_8 optml-bg-lazyloaded">
                  <div className="disclaimer_pb_column et_pb_column disclaimer_pb_column_4_4 et_pb_column_4_4 disclaimer_pb_column_11 et_pb_column_11  disclaimer_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough disclaimer-last-child et-last-child">
                    <div className="disclaimer_pb_module et_pb_module disclaimer_pb_text et_pb_text disclaimer_pb_text_8 et_pb_text_8 disclaimer_pb_text_align_center et_pb_text_align_center disclaimer_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                      <div className="disclaimer_pb_text_inner et_pb_text_inner">Disclaimer</div>
                    </div>
                  </div>
                </div>
                <div className="disclaimer_pb_bottom_inside_divider et_pb_bottom_inside_divider" style={{}} />
              </div>
              <div className="disclaimer_pb_section et_pb_section disclaimer_pb_section_8 et_pb_section_8 disclaimer_section_regular et_section_regular">
                <div className="disclaimer_pb_row et_pb_row disclaimer_pb_row_9 et_pb_row_9 optml-bg-lazyloaded">
                  <div className="disclaimer_pb_column et_pb_column disclaimer_pb_column_4_4 et_pb_column_4_4 disclaimer_pb_column_12 et_pb_column_12  disclaimer_pb_css_mix_blend_mode_passthrough et_pb_css_mix_blend_mode_passthrough disclaimer-last-child et-last-child">
                    <div className="disclaimer_pb_module et_pb_module disclaimer_pb_text et_pb_text disclaimer_pb_text_9 et_pb_text_9 disclaimer_pb_text_align_left et_pb_text_align_left disclaimer_pb_bg_layout_light et_pb_bg_layout_light optml-bg-lazyloaded">
                      <div className="disclaimer_pb_text_inner et_pb_text_inner">The information contained in this website is for general information purposes only. The information is provided by ARC Medical, and while we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is therefore strictly at your own risk.<p />
                        <p>In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
                        <p>Through this website you may be able to link to other websites which are not under the control of ARC Medical. We have no control over the nature, content and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
                        <p>Every effort is made to keep the website up and running smoothly. However, ARC Medical takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default Disclaimer